.TopPost-module_top-post-container_eh6xN__Main {
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
  min-height: 425px;
  max-height: 425px;
  display: block;
  position: relative;
  overflow: hidden;
}

.TopPost-module_top-post-container__gradient_W232w__Main {
  z-index: 1;
  background: linear-gradient(rgba(33, 33, 33, 0), rgba(33, 33, 33, .6) 38.31%, #212121 98.47%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
}

.TopPost-module_top-post-container__image_ZEeP6__Main {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.TopPost-module_top-post-container_eh6xN__Main img {
  transition: transform .2s;
  transform: translateZ(0);
}

.TopPost-module_top-post-container_eh6xN__Main:hover img {
  transform: scale(1.1);
}

.TopPost-module_top-post-container__time-and-label_7tV47__Main {
  margin-bottom: 10px;
}

.TopPost-module_top-post-container__label_pJ5jn__Main {
  color: var(--text-white);
  margin-left: 13px;
  position: relative;
}

.TopPost-module_top-post-container__label_pJ5jn__Main:before {
  content: "";
  background-color: var(--icons-white);
  border-radius: 50%;
  width: 3px;
  height: 3px;
  position: absolute;
  top: 50%;
  left: -7.5px;
  transform: translateY(-50%);
}

.TopPost-module_top-post-container__content_XY-GN__Main {
  z-index: 2;
  width: 60%;
  color: var(--text-white);
  flex-direction: column;
  display: flex;
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.TopPost-module_top-post-container__title_-VC9h__Main {
  color: var(--text-white);
}

.TopPost-module_top-post-container__description_cd0op__Main {
  margin-top: 5px;
}

.TopPost-module_top-post-container__comments_DjYMm__Main {
  margin-top: 20px;
  display: flex;
}

.TopPost-module_top-post-container__comments--count_bEpjE__Main span {
  color: var(--text-white);
}

@media (max-width: 660px) {
  .TopPost-module_top-post-container_eh6xN__Main {
    border-radius: 0;
    min-height: 255px;
    max-height: 255px;
  }

  .TopPost-module_top-post-container__content_XY-GN__Main {
    width: calc(100% - 40px);
    bottom: 10px;
  }
}

